<template>
    <div class="section">
        <div class="container">

            <div class="row p-0 m-0">
                <div class="col-12 col-lg-12">
                    <div class="">

                        <!--                        <router-link to="/" class="pl-lg-5">-->
                        <!--                    <img src="../../assets/web/login/home-icon.png" class="icon-sm mt-5" alt="">-->
                        <!--                </router-link>-->

                        <router-link to="/" class="fl-x-cl mt-4">
                            <img class="logo" src="../assets/web/login/logoo.png">
                        </router-link>
                        <!--                        <div class="fl-x-cr i-icon">-->
                        <!--                            <p class="mb-1 mr-3 fs&#45;&#45;1 font-lato-light">Sign up with</p>-->

                        <!--                            <img class="fb flj-c mr-2" src="../assets/web/login/fb.png">-->

                        <!--                            <img class="gugl fl-j-c" src="../assets/web/login/g.png">-->
                        <!--                        </div>-->
                    </div>
                    <div class="dg-sm-mt" align="center">
                        <p class="font-jost-regular  fs-4 mb-2 mg margin-1">Welcome to KLDB Training Programs</p>
                        <p class="font-jost-semi-bold fs-7 mb-0">Registration Portal</p>
                        <p @click="$router.push('/login/')" class="font-inter-Light fs--1 pb-4">Already have an Account
                            ?
                            <a href="#"> Sign in</a></p>
                        <div class="glass-panel h-100 col-lg-4 dg-sm-mt">
                            <section>
                                <slot>

                                    <p class="font-lato-semi-bold fs-2 mt-2 pt-4">Register As</p>
                                    <hr class="separator">
                                    <div @click="$router.push('/register-individual/')" class="pt-4">
                                        <btn border-radius="1"
                                             class="px-7 py-1 mw  c-btn-reg font-inter-medium fs--1 text-left bdr-radius lego-btn-chevron-close2">
                                            <div size="sm" class="mb-2 mt-2 pos-abs-left cursor-pointer">
                                                Individual
                                            </div>
                                        </btn>
                                    </div>
                                    <div @click="$router.push('/register-organization/')" class="pt-2">
                                        <btn border-radius="1"
                                             class="px-7 py-1 mw c-btn-reg bdr-radius mb-6 fs--1 mt-2 lego-btn-chevron-close2 ">
                                            <div size="sm"
                                                 class="mb-2 mt-2 font-inter-Medium pos-abs-left cursor-pointer">
                                                Organization
                                            </div>
                                        </btn>
                                    </div>
                                </slot>
                            </section>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>
<script>

export default {
    name: 'Registration',
    components: {}
};

</script>
<style scoped>

.btn-2 {
    padding: 5px 10px !important;
    border: none !important;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 3px !important;
}

.separator {
    height: 0.5px !important;
    background: rgba(255, 255, 255, 0.3);
    width: 121% !important;
    margin-left: -2rem !important;

}

.glass-panel {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.06);
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    border-radius: 0px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 2px;
    backdrop-filter: blur(10px);

}

.i-icon {
    position: absolute;
    top: 56px;
    right: -8px;
}

.logo {
    height: 78px;
    width: 148px;
}

.section {
    background-image: url("../assets/web/register/bgi.png");
    background-position: center 100%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;

}

.mw {
    min-width: 20rem;
    height: 3.1rem;
}

.pos-abs-left {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-98%);
}

.container {
    color: white;
}

.set-im img {
    width: 206px;
    height: 108px;
    /*margin-top: 6rem;*/
    /*margin-left: -10rem;*/
}

.fb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.gugl {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.place-holder-white {

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    /*/ / color: white !important;*/
    color: rgba(255, 255, 255, 0.7) !important;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    /*/ / color: white !important;*/
    color: rgba(255, 255, 255, 0.7) !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
    /*/ / color: white !important;*/
    color: rgba(255, 255, 255, 0.7) !important;
}

}
.mg {
    margin-top: -48px;
}

@media (max-width: 750px) {
    /*.dg-sm-mt {*/
    /*    margin-top: 50px;*/
    /*}*/
    .margin-1 {
        margin-top: 40px;
    }

    .mw {
        min-width: 16rem !important;
        height: 3.5rem;
    }
}
</style>
